import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  // eslint-disable-next-line max-len
  baseUrl = 'https://livelife-app-func.azurewebsites.net/api/send-register-otp-func?code=qEYU8Luguko66sdOilz4WH6/iJ7sfMejUcmzg8yE30nQH4MwvJNvug==';
  otp: number;
  appName = 'Bayede';

  constructor(
    private http: HttpClient
  ) { }

  // get the OTP
  getNewOTP(msisdn) {
    return this.http.get<number>(`${this.baseUrl}&appName=${this.appName}&msisdn=${msisdn}`);
  };

  setOTP(otp: number) {
    this.otp = otp;
  }

  getCurrentOTP() {
    return this.otp;
  }
}
